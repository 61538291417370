var tml = gsap.timeline({defaults: {duration: 1}})
tml.to(
    'body',
    {opacity: 1, ease: 'Power1.easeInOut'},
)
tml.fromTo(
    '#centered-div',
    {opacity: 1, backgroundColor: 'black', height: '100'},
    {
      visibility: 'hidden',
      height: '0',
      opacity: 0,
      backgroundColor: 'black',
      ease: 'Power1.easeInOut',
      delay: 2.5
    },
)
tml.to('.section_home-header1, .fab', {opacity: 1, ease: 'Power1.easeInOut'},
       '-=1')
    .to('.nav_component',
        {opacity: 1, y: 0, stagger: 0.1, ease: 'Power1.easeInOut'}, '-=1')
    // .fromTo(".nav_logo", { rotate: 0 }, { rotate: 360, ease:
    // "Power1.easeInOut" }, "-=0.6")
    .to('.header_wrapper', {opacity: 1, ease: 'Power1.easeInOut', margin: 0},
        '-=0.8')
    .to(
        '.home-header_lottie-desktop',
        {margin: '0', x: '-150', ease: 'Power2.easeInOut'},
        )
    .to('.home-header_lottie-mobile',
        {margin: '0', x: '0', opacity: '1', ease: 'Power2.easeInOut'}, '-=1')



let inttl = gsap.timeline({defaults: {duration: 1}})

inttl
    .to('.home-intro_heading',
        {opacity: 1, ease: 'Power.easeInOut', margin: '0', stagger: 0.1},
        '-=0.10')
    .to('.intro_image', {scale: '1', ease: 'Power.easeInOut', margin: '0'},
        '-=0.9')
    .to('.home-intro_text-container',
        {opacity: 1, ease: 'Power2.easeInOut', margin: '0', stagger: 0.1},
        '-=0.8')
    .to('.intro_button-container',
        {opacity: 1, ease: 'Power2.easeInOut', margin: '0', stagger: 0.1},
        '-=0.7')


ScrollTrigger.create({
  trigger: '.home-header_btn-container',
  start: 'top 20%',
  end: '+=500',
  animation: inttl,
  // scrub: true, //enterback
  // events: onEnter onLeave onEnterBack onLeaveBack
  // toggleActions: "restart play reverse reset"
});


let mctl = gsap.timeline({defaults: {duration: 1}})

mctl.to('.marquee-scroll', {margin: '0', ease: 'Power.easeInOut'}, '-=0.2')



ScrollTrigger.create({
  trigger: '.intro_button-container',
  start: 'bottom 100%',
  end: '+=2000',
  animation: mctl,
  scrub: true,  // enterback
  scrub: 1,
});



let hcrs = gsap.timeline({defaults: {duration: 1}})

hcrs.to(
    '.course-about_wrapper', {opacity: '1', ease: 'Power.easeInOut'}, '-=0.2')


ScrollTrigger.create({
  trigger: '.marquee-scroll',
  start: 'top 50%',
  end: '+=100',
  animation: hcrs,
  scrub: false,  // noenterback
  // scrub: true, //enterback
  // events: onEnter onLeave onEnterBack onLeaveBack
  // toggleActions: "restart play reverse reset"
});



let swptl = gsap.timeline({defaults: {duration: 1}})

swptl.to('.card-swiper', {opacity: 1, ease: 'Power.easeInOut'}, '-=0.2')



ScrollTrigger.create({
  trigger: '.course-about_wrapper',
  start: 'bottom 90%',
  end: '+=500',
  animation: swptl,
  // scrub: false, //noenterback
  // scrub: true, //enterback
  // events: onEnter onLeave onEnterBack onLeaveBack
  // toggleActions: "restart play reverse reset"
});



let stcd = gsap.timeline({defaults: {duration: 1}})

stcd.to(
    '#webd', {margin: '0', opacity: 1, ease: 'Power.easeInOut', stagger: 0.1},
    '-=0.10')
stcd.to(
    '#aut', {margin: '0', opacity: 1, ease: 'Power.easeInOut', stagger: 0.1},
    '-=0.9')
stcd.to(
    '#emb', {margin: '0', opacity: 1, ease: 'Power.easeInOut', stagger: 0.1},
    '-=0.8')

ScrollTrigger.create({
  trigger: '.structure_time-content-wrapper',
  start: 'top 60%',
  end: '+=500',
  animation: stcd,
  // scrub: true, //enterback
  // events: onEnter onLeave onEnterBack onLeaveBack
  // toggleActions: "restart play reverse reset"
});



let stcdtl = gsap.timeline({defaults: {duration: 1}})
stcd.to(
    '.testimonial_heading',
    {margin: '0', opacity: 1, ease: 'Power.easeInOut', stagger: 0.1}, '-=0.8')

ScrollTrigger.create({
  trigger: '#emb',
  start: 'top 50%',
  end: '+=500',
  animation: stcdtl,
  // scrub: true, //enterback
  // events: onEnter onLeave onEnterBack onLeaveBack
  // toggleActions: "restart play reverse reset"
});


let testscr = gsap.timeline({defaults: {duration: 2}})

testscr.to(
    '#tscd',
    {y: 400, ease: 'Power.easeInOut'},
)


ScrollTrigger.create({
  trigger: '.testimonial_heading',
  start: '0% 100%',
  end: '+=2500',
  animation: testscr,
  scrub: true,  // enterback
  scrub: 1,

});



const boxes = document.querySelectorAll('.marquee-scroll, #tscd');

window.addEventListener('scroll', () => {
  if (isElementVisible(box)) {
    animation.play();
  }
});
